.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

/* ----- FLEX FOR VOICE SECTION ----- */

/* Default styles for mobile */
.responsive-flex {
  display: block;
}

/* Styles for tablets and larger screens */
@media (min-width: 950px) {
  /* Adjust the breakpoint as needed */
  .responsive-flex {
    display: flex;
  }
}

/* ----- CAROUSEL BLURY RIGHT AND LEFT SIDE ----- */

.carouselContainer {
  position: relative;
  overflow: hidden;
}

.carouselContainer::before,
.carouselContainer::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(to right, #100022, transparent);
}

.carouselContainer::after {
  right: 0;
  transform: rotateZ(180deg);
}

/* ----- TABLES ----- */

table {
  width: 100%;
  border-spacing: 0px;
  border: 1px solid #eaecf0;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

thead {
}

/* case head */
th {
  background-color: #f9fafb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  padding: 12px 24px 12px 24px;
  text-align: left;
  white-space: nowrap;
  color: #656565;
}

tbody {
}

tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 11px;
}
tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 11px;
}
thead > tr:first-child > th:first-child {
  border-top-left-radius: 11px;
}
thead > tr:first-child > th:last-child {
  border-top-right-radius: 11px;
}

/* case body */
td {
  box-sizing: border-box;
  background: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  border-top: 1px solid #eaecf0;
  padding: 12px 24px 12px 24px;
}

/* line */
tr {
  /* cursor: pointer; */
  color: #333333;
}
